'use client'
import Image from 'next/image'
import LogoIconModv2 from '@/public/images/logo/logo_icon.svg'
import MobileMenu from './mobile-menu'
import { useState, useEffect } from 'react'
import { UserAuth } from '@/components/contexts/AuthContext'
import { Button } from '@/components/ui/elements/button'

export default function Header() {
    const [hasBlur, setHasBlur] = useState(false)
    const userAuth = UserAuth()

    useEffect(() => {
        const handleScroll = () => {
            setHasBlur(window.scrollY > 0)
        }

        window.addEventListener('scroll', handleScroll)

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <header id="navbar" className={`fixed top-0 z-30 w-full ${hasBlur ? 'backdrop-blur-xl' : ''}`}>
            <div className="mx-auto max-w-6xl px-4 sm:px-6">
                <div className="flex h-16 items-center justify-between">
                    {/* Site branding */}
                    <div className="mr-4 shrink-0">
                        {/* Logo */}
                        <Button
                            href="/"
                            className="p-0 hidden md:flex btn-sm text-neutral-300 hover:text-neutral-400"
                            label={
                                <span className=" items-center font-poppins text-xl font-light ">
                                    <span>
                                        <Image className="hover:brightness-75" src={LogoIconModv2} height={28} alt="Vocalist Icon" />
                                    </span>
                                </span>
                            }
                            disabledStyle="opacity-80"
                        />
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden font-inter md:flex md:grow">
                        {/* Desktop sign-in links */}
                        <ul className="flex grow flex-wrap items-center justify-start">
                            <Button href="/#examples" className="btn-sm text-neutral-300 hover:text-neutral-400" label="Examples" disabledStyle="opacity-80" />
                            <Button href="/subscribe" className="btn-sm text-neutral-300 hover:text-neutral-400" label="Pricing" disabledStyle="opacity-80" />
                        </ul>
                        <ul className="flex grow flex-wrap items-center justify-end">
                            {/* <li>
                                <Link href="/signin" className="font-medium text-vai-highlight-v2 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">
                                    Sign in
                                </Link>
                            </li> */}
                            <li>
                                {userAuth?.user ? (
                                    <Button
                                        href="/dashboard"
                                        className="btn-sm btn-vai-invert ml-3 px-3 text-neutral-300 hover:text-neutral-300"
                                        label="View Dashboard"
                                        isLoading={userAuth.isAuthenticating}
                                    />
                                ) : (
                                    <Button
                                        href="/signin"
                                        className="btn-sm btn-vai-invert ml-3 px-4 text-neutral-300 hover:text-neutral-300"
                                        label="Sign In"
                                        isLoading={!!userAuth?.isAuthenticating}
                                    />
                                )}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <MobileMenu />
        </header>
    )
}
