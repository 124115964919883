import { useState, useRef, useEffect } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import Image from 'next/image'
import { Menu, Close } from '@mui/icons-material'
import { UserAuth } from '@/components/contexts/AuthContext'
import LogoIcon from '@/public/images/logo/logo_icon.svg'

export default function MobileMenu() {
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

    const trigger = useRef<HTMLButtonElement>(null)
    const mobileNav = useRef<HTMLDivElement>(null)
    const userAuth = UserAuth()
    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }: { target: EventTarget | null }): void => {
            if (!mobileNav.current || !trigger.current) return
            if (!mobileNavOpen || mobileNav.current.contains(target as Node) || trigger.current.contains(target as Node)) return
            setMobileNavOpen(false)
        }
        document.addEventListener('click', clickHandler)
        return () => document.removeEventListener('click', clickHandler)
    })

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: { keyCode: number }): void => {
            if (!mobileNavOpen || keyCode !== 27) return
            setMobileNavOpen(false)
        }
        document.addEventListener('keydown', keyHandler)
        return () => document.removeEventListener('keydown', keyHandler)
    })

    return (
        <div className="fixed top-0 z-50 m-auto w-screen md:hidden">
            {/* Hamburger button */}
            <button
                ref={trigger}
                className={`hamburger relative z-50 ${mobileNavOpen && ''}`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
                {mobileNavOpen ? <Close className="m-4 h-6 w-6 text-white" /> : <Menu className="m-4 h-6 w-6 text-white" />}
            </button>

            {/*Mobile navigation */}
            <nav
                id="mobile-nav"
                ref={mobileNav}
                className="absolute top-0 z-20 w-full overflow-hidden px-0 font-medium text-gray-200 transition-all duration-300 ease-in-out "
                style={mobileNavOpen ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0.8 }}
            >
                <ul className="app-gradient-dark space-y-1 border-b border-white border-opacity-10 px-4 py-2 ">
                    <li>
                        <Link href="/" className="flex w-full justify-center  py-2 text-sm text-gray-200 hover:text-gray-300" onClick={() => setMobileNavOpen(false)}>
                            <Image className="hover:brightness-75" src={LogoIcon} height={24} alt="Vocalist.ai Icon" />
                        </Link>
                    </li>
                    <li>
                        <Link href="/#examples" className="flex w-full justify-center  py-2 text-sm text-gray-200 hover:text-gray-300" onClick={() => setMobileNavOpen(false)}>
                            Examples
                        </Link>
                    </li>
                    <li>
                        <Link href="/subscribe" className="flex w-full justify-center  py-2 text-sm text-gray-200 hover:text-gray-300" onClick={() => setMobileNavOpen(false)}>
                            Pricing
                        </Link>
                    </li>
                    <li>
                        {userAuth?.user ? (
                            <Link
                                href="/dashboard/transform"
                                className="btn-sm btn-vai-invert  flex justify-center py-2 text-sm text-gray-200 hover:text-gray-300"
                                onClick={() => setMobileNavOpen(false)}
                            >
                                View Dashboard
                            </Link>
                        ) : (
                            <Link href="/signin" className="flex w-full justify-center  py-2 text-sm text-gray-200 hover:text-gray-300 " onClick={() => setMobileNavOpen(false)}>
                                Sign in
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </div>
    )
}
