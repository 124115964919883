import React, { ReactNode } from 'react'
import { CircularProgress } from '@mui/material'
import { useState, useEffect } from 'react'
import Link from 'next/link'
// import { usePathname, useSearchParams } from 'next/navigation'
import { useTransition } from 'react'
import { useRouter } from 'next/navigation'

interface ButtonProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<any>
    href?: string
    label: string | ReactNode
    className?: string
    isLoading?: boolean
    isDisabled?: boolean
    disabledStyle?: string
}

export const Button = ({ onClick, href, label, className, isLoading, isDisabled = false, disabledStyle }: ButtonProps) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [isPending, startTransition] = useTransition()
    const disabledClasses = disabledStyle ? `disabled ${disabledStyle}` : 'disabled opacity-60'
    let router = useRouter()

    const isLoadingState = isProcessing || isLoading || isPending

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        startTransition(async () => {
            setIsProcessing(true)
            console.log('Processing')
            try {
                await onClick!(e)
            } finally {
                console.log('Done processing')
                setIsProcessing(false)
            }
        })
    }

    const hrefClick = (url: string) => {
        console.log('Processing')
        startTransition(() => {
            router.push(url?.toString())
            console.log('Done processing')
        })
    }

    if (href) {
        return (
            <Link
                href={href}
                onClick={(e) => {
                    e.preventDefault()
                    isLoadingState || isDisabled ? null : hrefClick(href)
                }}
            >
                <span
                    className={`btn relative flex items-center justify-center ${className} w-full disabled:pointer-events-none disabled:cursor-default ${
                        isLoadingState || isDisabled ? disabledClasses : ''
                    }`}
                >
                    <span className="relative">
                        <span className={isLoadingState ? 'invisible' : 'visible'}>{label}</span>
                        {isLoadingState && (
                            <span className="absolute inset-0 flex items-center justify-center">
                                <CircularProgress sx={{ height: '1.2rem', width: '1.2rem', color: 'white' }} size="1.2rem" />
                            </span>
                        )}
                    </span>
                </span>
            </Link>
        )
    }

    return (
        <button
            disabled={isLoadingState || isDisabled}
            onClick={handleClick}
            type="button"
            className={`btn flex items-center justify-center ${className} w-full disabled:pointer-events-none disabled:cursor-default ${isLoadingState || isDisabled ? disabledClasses : ''}`}
        >
            <span className="relative">
                <span className={isLoadingState ? 'invisible' : 'visible'}>{label}</span>
                {isLoadingState && (
                    <span className="absolute inset-0 flex items-center justify-center">
                        <CircularProgress sx={{ height: '1.2rem', width: '1.2rem', color: 'white' }} size="1.2rem" />
                    </span>
                )}
            </span>
        </button>
    )
}
